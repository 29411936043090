html,
body {
  height: 100%;
  margin: 0px;
}

#root {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.app-container {
  display: flex;
  flex-flow: column;
  height: inherit;
}
